import React,{ Component } from 'react';
import { DateHelper } from '../../_helpers/DateHelper';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import  Header from '../../_components/Header';
import  Footer from '../../_components/Footer';
import  Graphtone from './Graphtone';
import  Graphtonemedia from './Graphtonemedia';
import Graphtopissue from './Graphtopissue';
import Graphmediaanalysis from './Graphmediaanalysis';
import Graphtopicanalysis from './Graphtopicanalysis';
import Graphtonetopic from './Graphtonetopic';
import Graphtonenarasumber from './Graphtonenarasumber';
import Graphtonewartawan from './Graphtonewartawan';
import Graphtonejenis from './Graphtonejenis';
import Graphtonelembaga from './Graphtonelembaga';
import Graphtonerubrik from './Graphtonerubrik';

export default class Quantitative extends Component {

    constructor(props){
        super(props);
        this.state = {
            id : '',
            data : [],
            dateFrom : DateHelper(),
            dateTo : DateHelper(),
            onlineNews : "",
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        
        this.forceUpdateTone = React.createRef();
        this.forceUpdateToneMedia = React.createRef();
        this.forceUpdateToneTopik = React.createRef();
        this.forceUpdateToneNarasumber = React.createRef();
        this.forceUpdateToneWartawan = React.createRef();
        this.forceUpdateToneJenis = React.createRef();
        this.forceUpdateToneLembaga = React.createRef();
        this.forceUpdateToneRubrik = React.createRef();
        this.forceUpdateTopIsu = React.createRef();
        this.forceUpdateAnalisaMedia = React.createRef();
        this.forceUpdateAnalisaTopik = React.createRef();
    }


    handleChange(event){
        this.setState({
            [event.target.name] : event.target.value
        });
    }

    handleSubmit(event){
        if(this.state.dateFrom === '' || this.state.dateTo === '' )
            return   
            
        this.forceUpdateTone.current.forceUpdateComponent(this.state.dateFrom, this.state.dateTo);
        this.forceUpdateToneMedia.current.forceUpdateComponent(this.state.dateFrom, this.state.dateTo);
        this.forceUpdateTopIsu.current.forceUpdateComponent(this.state.dateFrom, this.state.dateTo);
        this.forceUpdateAnalisaMedia.current.forceUpdateComponent(this.state.dateFrom, this.state.dateTo);
        this.forceUpdateAnalisaTopik.current.forceUpdateComponent(this.state.dateFrom, this.state.dateTo);
        this.forceUpdateToneTopik.current.forceUpdateComponent(this.state.dateFrom, this.state.dateTo);
        this.forceUpdateToneNarasumber.current.forceUpdateComponent(this.state.dateFrom, this.state.dateTo);
        this.forceUpdateToneWartawan.current.forceUpdateComponent(this.state.dateFrom, this.state.dateTo);
        this.forceUpdateToneJenis.current.forceUpdateComponent(this.state.dateFrom, this.state.dateTo);
        this.forceUpdateToneLembaga.current.forceUpdateComponent(this.state.dateFrom, this.state.dateTo);
        this.forceUpdateToneRubrik.current.forceUpdateComponent(this.state.dateFrom, this.state.dateTo);
        
    }

    
     
    render(){        

        return (

            <div>
                <Header></Header>

                <br></br>
                    <div className="blog-area section-padding-0-80">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="contact-title" style={{float:'left'}}>
                                    <h2>Quantitative Report</h2>
                                </div>

                                <div  className="form-inline float-right">
                                    <input type="date" className="form-control my-1 mr-sm-2" name="dateFrom" onChange={this.handleChange } value={this.state.dateFrom} placeholder="Date From" />
                                    <input type="date" className="form-control my-1 mr-sm-2" name='dateTo' onChange={this.handleChange } value={this.state.dateTo} placeholder="Date to" />
                                    <button type="submit" onClick={this.handleSubmit} className="btn  btn-success my-1 mr-sm-2">
                                        {this.state.isLoading ? <FontAwesomeIcon style={{marginRight:5}} icon={faSpinner} spin/> : '' } Submit
                                    </button>
                                </div>

                            </div>
                        </div>
                        <div className='clearfix'></div>
                        <br></br>
                        <div className="row">
                                <div className='col-12 col-md-6 graph-container'>
                                    <Graphtone title="Grafik Tone Berita" width="300" height="200" dateFrom={this.state.dateFrom} dateTo={this.state.dateTo} ref={this.forceUpdateTone} />
                                </div>
                                <div className='col-12 col-md-6 graph-container'>
                                    <Graphtonemedia title="Grafik Tone Media" width="300" height="200" dateFrom={this.state.dateFrom} dateTo={this.state.dateTo} ref={this.forceUpdateToneMedia} />
                                </div>

                                <div className='col-12 col-md-6 graph-container'>
                                    <Graphtonetopic title="Grafik Tone Topik" width="300" height="200" dateFrom={this.state.dateFrom} dateTo={this.state.dateTo} ref={this.forceUpdateToneTopik} />
                                </div>

                                <div className='col-12 col-md-6 graph-container'>
                                    <Graphtonenarasumber title="Grafik Tone Narasumber" width="300" height="200" dateFrom={this.state.dateFrom} dateTo={this.state.dateTo} ref={this.forceUpdateToneNarasumber}/>
                                </div>

                                <div className='col-12 col-md-6 graph-container'>
                                    <Graphtonewartawan title="Grafik Tone Wartawan" width="300" height="200" dateFrom={this.state.dateFrom} dateTo={this.state.dateTo} ref={this.forceUpdateToneWartawan} />
                                </div>

                                <div className='col-12 col-md-6 graph-container'>
                                    <Graphtonejenis title="Grafik Tone Jenis" width="300" height="200" dateFrom={this.state.dateFrom} dateTo={this.state.dateTo} ref={this.forceUpdateToneJenis} />
                                </div>

                                <div className='col-12 col-md-6 graph-container'>
                                    <Graphtonelembaga title="Grafik Tone Lembaga" width="300" height="200" dateFrom={this.state.dateFrom} dateTo={this.state.dateTo} ref={this.forceUpdateToneLembaga} />
                                </div>

                                <div className='col-12 col-md-6 graph-container'>
                                    <Graphtonerubrik title="Grafik Tone Rubrik" width="300" height="200" dateFrom={this.state.dateFrom} dateTo={this.state.dateTo} ref={this.forceUpdateToneRubrik} />
                                </div>  

                                <div className='col-12 col-md-6 graph-container'>
                                    <Graphtopissue title="Grafik Top 5 Isu" width="300" height="200" dateFrom={this.state.dateFrom} dateTo={this.state.dateTo} ref={this.forceUpdateTopIsu}/>
                                </div>
                                <div className='clearfix'></div>
                                <br></br>
                                <div className='col-12 col-md-6 graph-container'>
                                    <Graphmediaanalysis title="Grafik Analisa Media" width="300" height="200" dateFrom={this.state.dateFrom} dateTo={this.state.dateTo} ref={this.forceUpdateAnalisaMedia} />
                                </div>  

                                <div className='col-12 col-md-6 graph-container'>
                                    <Graphtopicanalysis title="Grafik Analisa Topik" width="300" height="200" dateFrom={this.state.dateFrom} dateTo={this.state.dateTo} ref={this.forceUpdateAnalisaTopik} />
                                </div>


                               
                            
                        </div>
                    </div>
                </div>


                <Footer></Footer>
            </div>
        ) 
    }
  
}

